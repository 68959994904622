import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as style from './fanpage-organism.module.scss'
import Advertisement from '../../../advertisments-module/atoms/advertisement/advertisement';
import AdvertisementGroup from '../../../advertisments-module/molecules/advertisement-group/advertisement-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUrlSlug, redirect, urlGgeneratedByLanguage } from '../../../../utils/utils';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios'

const FanpageOrganism = ({sportId, sportTitle, sportDesc = '', logo, logoAlt, close, midAdvertisement, advertisements}) => {

    const [clubs, setClubs] = useState([])
    const { language } = useI18next()


    useEffect(() => {
      axios.get(`${urlGgeneratedByLanguage(language)}/api/get-club-of-sport/${sportId}`).then(res => {
        setClubs(res.data)
      })
    }, [])

    return (
        <section>
                <div className={style.containerContents}>

                  <div className={style.containerLinkBack}>
                    <a onClick={close}><FontAwesomeIcon icon={'angle-left'} size="lg" /> Back</a>
                  </div>

                  <section className={style.sectionClubs}>
                    <div className={style.sportInfo}>
                        <figure>
                          {logo && <img src={logo} />}
                          {!logo && <FontAwesomeIcon icon={'medal'} size="3x" />}
                        </figure>
                        <div className={style.sportInfoContent}>
                          <h1>{sportTitle}</h1>
                          <p className={style.clubDescriptionDesktop}>{sportDesc.slice(0, 222)}{(sportDesc.length > 222 && '...')}</p>
                        </div>
                    </div>
                    <p className={style.clubDescriptionMobile}>{sportDesc.slice(0, 222)}{(sportDesc.length > 222 && '...')}</p>
                    <ul className={style.clubs}>
                      {clubs.map(club =><li onClick={() => redirect(`/main/entity/${createUrlSlug(club?.title)}?id=${club?.club_id}`)}>{club.title}</li>)}
                    </ul>
                  </section>

                  <div className={style.advertisementMid}>
                  {!!midAdvertisement && midAdvertisement.campaignId && <Advertisement campaignId={midAdvertisement.campaignId}  mediaUrl={midAdvertisement.media_link}
                        addUUID={`${midAdvertisement.id}-landing-${midAdvertisement.position}`}
                        destinationLink={midAdvertisement.url}
                        mediaType={midAdvertisement.media_type}/>}
                        <AdvertisementGroup advertisements={advertisements} className={style.groupAd}/>
                  </div>
                </div>
                <div>
                  
                </div>
        </section>
    );
};

FanpageOrganism.propTypes = {
    advertisements: PropTypes.array,
    midAdvertisement: PropTypes.object,
    sportId: PropTypes.number,
    sportTitle: PropTypes.string
};

export default FanpageOrganism;