import React, { useEffect, useState } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { loadManyAds, readAndGenerateTopAd, getHomeSideAd, getUniqueSideAds } from '../../services/ads-service'
import Layout from '../../templates/layout'
import Skeleton from '../../components/main-page-module/organisms/skeleton/skeleton'
import Title from '../../components/core-module/atoms/page/title'
import * as style from './fanpages.module.scss'
import { graphql } from 'gatsby'
import FanpageOrganism from '../../components/main-page-module/organisms/fanpage-organism/fanpage-organism'
import SportsOrganism from '../../components/main-page-module/organisms/sports-organism/sports-organism'



const Fanpages = () => {

    const { language } = useI18next()
    const [midAdvertisement, setMidAd] = useState([])
    const [advertisements, setAdvertisements] = useState([])
    const [sportId, setSportId] = useState(null)
    const [sportTitle, setSportTitle] = useState('')
    const [sportDesc, setSportDesc] = useState('')
    const [logo, setLogo] = useState('')
    const [logoAlt, setLogoAlt] = useState('')

    const choose = (value) => {
      if(value.id) {
        setSportId(value.id)
        setSportTitle(value.club)
        setLogo(value.logo)
        setLogoAlt(value.logo_alt)
      }
    }

    const clearSport = () => {
      setSportId(null)
      setSportTitle('')
      setSportDesc('')
    }

    useEffect(() => {
      loadManyAds(language,'home=1').then(res => {
                readAndGenerateTopAd(res.data)
                let groupAds = getUniqueSideAds(res.data, 3)
                setAdvertisements(groupAds)
                let midAd = getHomeSideAd(res.data)                    
                setMidAd(midAd)
        })
    },[])

    return <Layout>  
            <Skeleton>
                {/* ADD BREADCRUMB COMPONENT HERE */}
                <Title>Fanpages</Title>
            </Skeleton>
            <Skeleton className={style.sectionList}>
                {!sportId && <SportsOrganism midAdvertisement={midAdvertisement} advertisements={advertisements} select={choose}/>}
                {sportId && <FanpageOrganism 
                                midAdvertisement={midAdvertisement} 
                                advertisements={advertisements} 
                                sportId={sportId} 
                                sportTitle={sportTitle} 
                                sportDesc={sportDesc}
                                logo={logo}
                                logoAlt={logoAlt}
                                close={clearSport}
                            />}
            </Skeleton>
    </Layout>

}


export default Fanpages


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
