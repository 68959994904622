import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as style from './sports-organism.module.scss'
import FilterSelect from '../../../core-module/atoms/filter-select/filter-select';
import MapLink from '../../atoms/map-link/map-link';
import Advertisement from '../../../advertisments-module/atoms/advertisement/advertisement';
import AdvertisementGroup from '../../../advertisments-module/molecules/advertisement-group/advertisement-group';
import { loadMenu } from '../../../../services/menu-service';
import SportList from '../sport-list/sport-list';

const SportsOrganism = ({select, midAdvertisement, advertisements}) => {

    const [menu, setMenu] = useState([])

    useEffect(async () => {
        setMenu(await loadMenu())
    }, [])

    return (
        <div>
            <div className={style.containerFilters}>
                  <FilterSelect icon='basketball-ball' data={menu} select={select} className={style.filterSelect}/>
                  <MapLink />
                </div>
                <div className={style.containerContents}>
                  <div className={style.sportList}>
                    {menu.map(sport => <SportList sport={sport.sport} clubs={sport.clubs} select={select}/>)}
                  </div>

                  <div className={style.advertisementMid}>
                  {!!midAdvertisement && midAdvertisement.campaignId && <Advertisement campaignId={midAdvertisement.campaignId}  mediaUrl={midAdvertisement.media_link}
                        addUUID={`${midAdvertisement.id}-landing-${midAdvertisement.position}`}
                        destinationLink={midAdvertisement.url}
                        mediaType={midAdvertisement.media_type}/>}
                  </div>
                </div>
                <div>
                  <AdvertisementGroup advertisements={advertisements}/>
                </div>
        </div>
    );
};

SportsOrganism.propTypes = {
    advertisements: PropTypes.array,
    midAdvertisement: PropTypes.object,
    select: PropTypes.func
};

export default SportsOrganism;